import React from "react";
// import Weather from "../weather/Weather";
import Login from "./Login";

const Home = () => {
  // return <Weather />;
  return <Login />;
};

export default Home;
