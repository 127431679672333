import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import Close from "@mui/icons-material/Close";
import DateDifferenceComponent from "../../time/DateDifferenceComponent";
import ZonalDataPerDayInterface from "../../time/ZonalDataPerDayInterface";
import BASE_URL from "../../../auth/dbUrl";
import convertToBengaliNumber from "../../time/NumberConverter";

function AdminDataPerDayCount() {
  const { dayId, zonalId, branchId, noticeId } = useParams();
  const [descriptionAlert, setDescriptionAlert] = useState(false);

  const [notice, setNotice] = useState();
  const [totalData, setTotalData] = useState();
  const [thanaReport, setThanaReport] = useState();
  const [branchName, setBranchName] = useState();

  useEffect(() => {
    const getZonalUsers = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/admin/thana-data-daycount/${dayId}/${zonalId}/${branchId}/${noticeId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "myworld " + window.localStorage.getItem("gsmToken"),
            },
          }
        );
        const data = await response.json();

        if (response.ok) {
          setThanaReport(data.tempThana);
          setNotice(data.question);
          setTotalData(data.sumsArray);
          setBranchName(data.branch);
        } else {
          throw new Error("Failed to fetch");
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching notice data:", error);
      }
    };
    getZonalUsers();
  }, [noticeId, dayId, branchId, zonalId]);
  const descriptionHandler = () => {
    setDescriptionAlert(true);
  };
  const descriptionCloserHandler = () => {
    setDescriptionAlert(false);
  };
  const validCardData = (endDadeline) => {
    const currentDate = new Date();
    const endDadelineDate = new Date(endDadeline);

    const timeDiff = endDadelineDate - currentDate;
    const diffInDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return diffInDays;
  };

  return (
    <>
      <div className="table-responsive">
        <div className="card border-0 my-1">
          <div className="card-header border-0">
            <div className="myTopCard col-lg-8 col-md-6 col-sm-12 m-auto">
              {descriptionAlert && (
                <div className="docsPopUp">
                  <Button
                    onClick={descriptionCloserHandler}
                    className=" float-end"
                  >
                    <Close />
                  </Button>
                  {notice?.doc_desc}
                </div>
              )}
            </div>
            <div className="card-header">
              <div className="row">
                <div className="answerLeft col-lg-3 col-md-3 col-sm-12 m-auto">
                  <div className="border p-2">
                    {validCardData(notice?.endDadeline) < 0 ? (
                      <p className="text-center fs-6 fw-bold text-danger">
                        নোটিশ প্রদানের সময় শেষ হয়েছে{" "}
                        {convertToBengaliNumber(
                          Math.abs(validCardData(notice?.endDadeline))
                        )}{" "}
                        দিন পূর্বে
                      </p>
                    ) : (
                      <DateDifferenceComponent
                        startDadeline={notice?.startDadeline}
                        range={notice?.range}
                        timeStart={notice?.timeStart}
                        timeEnd={notice?.timeEnd}
                        endDadeline={notice?.endDadeline}
                      />
                    )}
                  </div>
                </div>

                <div className="answerMiddle col-lg-6 col-md-6 col-sm-12 m-auto mt-0">
                  <p className="text-center fs-2 fw-semibold text-success">
                    {notice?.document_name}
                  </p>
                  {notice?.sub_title && (
                    <p className="text-center fs-6">{notice?.sub_title}</p>
                  )}
                  <p className="text-center">
                    <span className="fs-3 fw-bold text-highlight bg-success rounded px-2">
                      এক নজরে থানা রিপোর্ট
                    </span>
                  </p>
                </div>
                <div className="answerRight col-lg-3 col-md-3 col-sm-12 m-auto mt-0">
                  <div className="d-flex align-items-end justify-content-end flex-column">
                    {!descriptionAlert && (
                      <Button
                        onClick={descriptionHandler}
                        className="text-center border border-success fw-semibold w-50"
                      >
                        Notice
                      </Button>
                    )}
                    <Link
                      className="button p-2 fs-5"
                      to={`/dashboard/admin-branch-interface/${dayId}/${zonalId}/${noticeId}`}
                    >
                      <span>Back</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body shadow my-3 bg-white p-3 rounded">
          <ZonalDataPerDayInterface
            startDadeline={notice?.startDadeline}
            range={notice?.range}
            questions={notice?.questions}
            thanaReport={thanaReport}
            totalData={totalData}
            branchName={branchName}
          />
        </div>
      </div>
    </>
  );
}

export default AdminDataPerDayCount;
