import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./frontend/Home";
// import About from "./frontend/About";
// import Contact from "./frontend/Contact";
import Login from "./frontend/Login";
import FrontentLayouts from "./layouts/FrontentLayouts";
import BackendLayouts from "./layouts/BackendLayouts";
import Notice from "./dashboard/Notice";
import NoticeBoard from "./dashboard/NoticeBoard";
import AuthContextProvider from "./contexts/AuthContext";
import AuthRoutes from "./routes/AuthRoutes";
import NoticeTable from "./dashboard/QuestionAnswer";
import ZonalUsers from "./dashboard/users/ZonalUsers";
import BranchUsers from "./dashboard/users/BranchUsers";
// import BranchSubmission from "./dashboard/users/database/BranchSubmission";
import ZonalSubmission from "./dashboard/users/database/ZonalSubmission";
import AdminReview from "./dashboard/users/database/AdminReview";
import Success from "./frontend/Success";
import EditQuestionAnswer from "./dashboard/users/database/EditQuestionAnswer";
import BranchEmptyNotice from "./dashboard/users/database/BranchEmptyNotice";
import ThanaUserInterface from "./dashboard/pages/ThanaUserInterface";
import ThanaEmptyNotice from "./dashboard/pages/ThanaEmptyNotice";
import BranchUserInterface from "./dashboard/pages/BranchUserInterface";
import BranchDataInterface from "./dashboard/pages/BranchDataInterface";
import ZonalDataInterface from "./dashboard/pages/regional/ZonalDataInterface";
import ZonalUserInterface from "./dashboard/pages/regional/ZonalUserInterface";
import ZonalDataPerDayCount from "./dashboard/pages/regional/ZonalDataPerDayCount";
import AdminDataInterface from "./dashboard/pages/admin/AdminDataInterface";
import AdminUserInterface from "./dashboard/pages/admin/AdminUserInterface";
import AdminBranchUserInterface from "./dashboard/pages/admin/AdminBranchUserInterface";
import AdminDataPerDayCount from "./dashboard/pages/admin/AdminDataPerDayCount";
import NoticeEditor from "./dashboard/NoticeEditor";
import BranchUserCreate from "./dashboard/usersCreateUpdate/create/BranchUserCreate";
import RegionalUserCreate from "./dashboard/usersCreateUpdate/create/RegionalUserCreate";
import ThanaUsersCreate from "./dashboard/usersCreateUpdate/create/ThanaUsersCreate";
import BranchUsersUpdate from "./dashboard/usersCreateUpdate/update/BranchUsersUpdate";
import RegionalUsersUpdate from "./dashboard/usersCreateUpdate/update/RegionalUsersUpdate";
import ThanaUsersUpdate from "./dashboard/usersCreateUpdate/update/ThanaUsersUpdate";

import UpdateRegionalPassword from "./dashboard/usersCreateUpdate/forgetPassword/UpdateRegionalPassword";
import UpdateBranchPassword from "./dashboard/usersCreateUpdate/forgetPassword/UpdateBranchPassword";
import UpdateThanaPassword from "./dashboard/usersCreateUpdate/forgetPassword/UpdateThanaPassword";
import UploadUserFile from "./dashboard/usersCreateUpdate/UploadUserFile";
import PageNotFound from "./frontend/PageNotFound";
import ZonalBranchUsersTable from "./dashboard/users/usersTable/ZonalBranchUsersTable";
import ZonalBranchThana from "./dashboard/users/ZonalBranchThana";
import ThanaUsers from "./dashboard/users/ThanaUsers";
import SumsAllZonalData from "./dashboard/pages/sumsData/SumsAllZonalData";
import SumsAllBranchData from "./dashboard/pages/sumsData/SumsAllBranchData";
import SumsAllThanaData from "./dashboard/pages/sumsData/SumsAllThanaData";
import SumsThanaByBranches from "./dashboard/pages/sumsData/SumsThanaByBranches";
import EditQuestionAnswerByThana from "./dashboard/pages/EditQuestionAnswerByThana";
import SumsTotalDayThanaData from "./dashboard/pages/sumsData/SumsTotalDayThanaData";
import SumsDayByDayBranchData from "./dashboard/pages/sumsData/SumsDayByDayBranchData";
import SumsDayByDayZonalData from "./dashboard/pages/sumsData/SumsDayByDayZonalData";
import SumsZonalDataByBranch from "./dashboard/pages/sumsData/SumsZonalDataByBranch";

function App() {
  return (
    <AuthContextProvider>
      <HashRouter>
        <Routes>
          <Route path="/" element={<FrontentLayouts />}>
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path={"success"} element={<Success />} />
          </Route>

          <Route
            path="dashboard"
            element={
              <AuthRoutes>
                <BackendLayouts />
              </AuthRoutes>
            }
          >
            <Route index element={<NoticeBoard />} />
            <Route path={"notice/:id"} element={<Notice />} />
            <Route path={"notice-edit/:id"} element={<NoticeEditor />} />
            <Route path={"notice-answer/:id"} element={<NoticeTable />} />
            {/* user csv  file upoloader  path  */}
            <Route path={"upload-user-file"} element={<UploadUserFile />} />
            <Route path={"branch-users"} element={<BranchUsers />} />
            <Route path={"branch-thana/:branchId"} element={<ThanaUsers />} />
            <Route path={"zonal-users"} element={<ZonalUsers />} />
            {/* zonal ==> branch ==> thana  */}
            <Route
              path={"zonal-branch/:zonalId/branch-thana/:branchId"}
              element={<ZonalBranchThana />}
            />
            <Route
              path={"zonal-branch/:zonalId"}
              element={<ZonalBranchUsersTable />}
            />
            {/* user create and update  */}
            <Route path={"create-branch"} element={<BranchUserCreate />} />
            <Route path={"update-branch/:id"} element={<BranchUsersUpdate />} />
            <Route
              path={"update-branch-password/:id"}
              element={<UpdateBranchPassword />}
            />
            <Route path={"create-zonal"} element={<RegionalUserCreate />} />
            <Route
              path={"update-zonal/:id"}
              element={<RegionalUsersUpdate />}
            />
            <Route
              path={"update-zonal-password/:id"}
              element={<UpdateRegionalPassword />}
            />
            <Route path={"create-thana"} element={<ThanaUsersCreate />} />
            <Route path={"update-thana/:id"} element={<ThanaUsersUpdate />} />
            <Route
              path={"update-thana-password/:id"}
              element={<UpdateThanaPassword />}
            />
            {/* branch data interface  */}
            <Route
              path={"branch-interface/:dayId/:noticeId"}
              element={<BranchUserInterface />}
            />
            <Route
              path={"branch-edit-answer/:formId/:answerId"}
              element={<EditQuestionAnswer />}
            />
            <Route
              path={"branch-empty-answer/:firstId/:secondId"}
              element={<BranchEmptyNotice />}
            />
            <Route
              path={"branch-data-interface/:id"}
              element={<BranchDataInterface />}
            />
            {/* thana user data submit and edit and update  */}
            <Route
              path={"thana-empty-answer/:id"}
              element={<ThanaEmptyNotice />}
            />
            <Route
              path={"thana-edit-answer/:id/:answerId"}
              element={<EditQuestionAnswerByThana />}
            />
            <Route
              path={"thana-submission/:id"}
              element={<ThanaUserInterface />}
            />
            {/* zonal user data submit and edit and update  */}
            <Route
              path={"zonal-submission/:id"}
              element={<ZonalSubmission />}
            />
            <Route
              path={"zonal-data-interface/:id"}
              element={<ZonalDataInterface />}
            />
            <Route
              path={"zonal-data-perDayCount/:dayId/:branchId/:noticeId"}
              element={<ZonalDataPerDayCount />}
            />
            <Route
              path={"zonal-interface/:dayId/:noticeId"}
              element={<ZonalUserInterface />}
            />
            {/* admin user data submit and edit and update  */}
            <Route
              path={"admin-data-interface/:id"}
              element={<AdminDataInterface />}
            />
            <Route
              path={"admin-interface/:dayId/:noticeId"}
              element={<AdminUserInterface />}
            />
            <Route
              path={"admin-branch-interface/:dayId/:zonalId/:noticeId"}
              element={<AdminBranchUserInterface />}
            />
            {/* <Route
              path={"admin-all-branch-interface/:dayId/:zonalId/:noticeId"}
              element={<AdminAllBranchUserInterface />}
            /> */}
            <Route
              path={
                "admin-data-perDayCount/:dayId/:zonalId/:branchId/:noticeId"
              }
              element={<AdminDataPerDayCount />}
            />
            <Route path={"admin-submission"} element={<AdminReview />} />
            {/* susms all data  */}
            <Route
              path={"sums-all-zonal-data/:qId"}
              element={<SumsAllZonalData />}
            />
            <Route
              path={"sums-day-by-day-zonal-data/:qId/:zId"}
              element={<SumsDayByDayZonalData />}
            />
            <Route
              path="sums-zonal-data-by-branch/:qId/:zId"
              element={<SumsZonalDataByBranch />}
            />
            <Route
              path={"sums-all-branches-data/:qId"}
              element={<SumsAllBranchData />}
            />
            <Route
              path={"sums-all-thana-data/:qId"}
              element={<SumsAllThanaData />}
            />
            <Route
              path={"sums-Totol-day-thana-data/:qId/:zId/:bId/:tId"}
              element={<SumsTotalDayThanaData />}
            />
            <Route
              path={"sums-thana-by-branch/:qId/:bId"}
              element={<SumsThanaByBranches />}
            />{" "}
            <Route
              path={"sums-day-by-day-branch-data/:qId/:zId/:bId"}
              element={<SumsDayByDayBranchData />}
            />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </HashRouter>
    </AuthContextProvider>
  );
}

export default App;
